export default class Popup {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
    this.timeoutEvent()
  }

  setVars() {
    this.button = this.el.querySelector('button')
  }

  bindEvents() {
    this.button.addEventListener('click', this.remove)
  }

  timeoutEvent() {
    setTimeout(() => {
      this.button.click()
    }, '3000')
  }

  remove = () => {
    this.el.parentNode.removeChild(this.el)
  }
}

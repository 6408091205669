import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    isSubmitting: false
  }

  static targets = ['input', 'previewText']

  handleDrop(e) {
    const transfer = e?.detail?.dataTransfer
    this.uploadFiles(transfer?.files)
  }

  handleChange(e) {
    // prevent submitting on file change when drag and drop is happening
    if (this.isSubmittingValue === true) {
      return
    }

    const target = e.target || e.dataTransfer
    this.uploadFiles(target?.files)
  }

  uploadFiles(files) {
    if (!files || !files.length) {
      console.warn('unexpected value for `files`', files)
      return
    }

    this.isSubmittingValue = true
    this.inputTarget.files = files

    if (this.hasPreviewTextTarget) {
      this.isSubmittingValue = false
      this.createPreview(files[0])
    } else {
      this.createDocument()
    }
  }

  createPreview(file) {
    this.previewTextTarget.textContent = file?.name
  }

  createDocument() {
    let form = this.inputTarget.closest('form')

    if (form.dataset.remote) {
      // submits the form using rails-ujs so the `ajax:complete`
      // event will fire (captured in inline-edit.js)
      window.Rails.fire(form, 'submit')
    } else {
      form.submit()
    }
  }
}

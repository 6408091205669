import { selectAll } from '../scripts/utils'

export default class Ellipsis {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.button = this.el.querySelector('.ellipsis__target')
    this.links = selectAll('.ellipsis-link', this.el)

    this.links.forEach(el => {
      el.setAttribute('tabindex', -1)
    })
  }

  bindEvents() {
    this.button.addEventListener('click', this.toggle)
    document.body.addEventListener('click', this.close)
  }

  toggle = e => {
    e.stopPropagation()
    const isOpen = this.el.classList.contains('-open')

    if (!isOpen) {
      this.el.classList.add('-open')
      this.el.classList.add('-prepare')

      this.links.forEach(el => {
        el.removeAttribute('tabindex')
      })
    } else {
      this.close()
    }
  }

  close = () => {
    this.links.forEach(el => {
      el.setAttribute('tabindex', -1)
    })

    this.el.classList.remove('-open')
    setTimeout(() => {
      this.el.classList.remove('-prepare')
    }, 250)
  }
}

export const selectAll = (selector, root = document) => {
  return Array.prototype.slice.call(root.querySelectorAll(selector), 0)
}

export const safeSelect = (root, selector, fallback) => {
  return root.querySelector(selector) || document.createElement(fallback)
}

export const htmlDecode = input => {
  const el = document.createElement('div')
  el.innerHTML = input
  return el.childNodes.length === 0 ? '' : el.childNodes[0].nodeValue
}

import { selectAll } from '../scripts/utils'

export default class Nav {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    //TODO may want to change to data attribute
    this.items = selectAll('.nav__item', this.el).map(item => {
      return {
        el: item,
        trigger: item.querySelector('.nav__trigger'),
        submenu: item.querySelector('ul')
      }
    })
  }

  bindEvents() {
    this.items.forEach(item => {
      item.trigger.addEventListener('click', e => {
        e.stopPropagation()
        e.preventDefault()
        if (item.trigger.classList.contains('active-nav') === true) {
          this.closeSubmenus(item.trigger, item.submenu)
        } else {
          this.openSubmenus(item.trigger, item.submenu)
        }
      })
    })

    // close all menus when clicking off menu dropdown
    document.body.addEventListener('click', e => {
      this.closeAll()
    })
  }

  closeAll = () => {
    this.items.forEach(item => {
      item.trigger.setAttribute('aria-expanded', false)
      item.trigger.classList.remove('active-nav')
      item.submenu?.setAttribute('aria-hidden', true)
    })
  }

  openSubmenus(trigger, submenu) {
    this.closeAll()
    trigger.setAttribute('aria-expanded', true)
    trigger.classList.add('active-nav')
    submenu?.removeAttribute('aria-hidden')
  }

  closeSubmenus(trigger, submenu) {
    this.closeAll()
    trigger.toggleAttribute('aria-expanded', false)
    trigger.classList.remove('active-nav')
    submenu?.removeAttribute('aria-hidden')
  }
}

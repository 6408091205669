/* eslint no-console:0 */
import '../scripts/initialize/globals'
import '../scripts/initialize/sentry'
import '../scripts/initialize/stimulus'
import 'what-input'
import { Turbo } from '@hotwired/turbo-rails'

// Disable Turbo Drive to not mess with Viget module pattern
// https://turbo.hotwired.dev/handbook/building
Turbo.session.drive = false

function loadModuleByName(modules, el, name) {
  const modulePath = Object.keys(modules).find(
    path => path.indexOf(name) !== -1
  )
  if (modules[modulePath]) {
    const Module = modules[modulePath]
    try {
      new Module(el)
    } catch (err) {
      console.error('error initializing module for el', el, err)
    }
  } else {
    console.error('missing module for el', el, modulePath)
  }
}

const modules = import.meta.glob('../modules/*.js', {
  import: 'default',
  eager: true
})

const moduleElements = document.querySelectorAll('[data-module]')

Array.from(moduleElements).forEach(el => {
  // support one or more space-separated module names
  el.getAttribute('data-module')
    .split(' ')
    .forEach(name => {
      loadModuleByName(modules, el, name)
    })
})

// doing this bc no easy way to add data attrs to every simple_form
const formModuleElements = document.querySelectorAll('form')
Array.from(formModuleElements).forEach(el => {
  loadModuleByName(modules, el, 'forms')
})

import { Controller } from '@hotwired/stimulus'

// why this?
// example: https://stackoverflow.com/questions/6218494/using-the-html5-required-attribute-for-a-group-of-checkboxes
export default class extends Controller {
  static targets = ['requiredCheckbox']

  connect() {
    // simple_form does not set the required attribute, via `input_html: {}` for the collection
    this.requiredCheckboxTargets.forEach(c => {
      c.setAttribute('required', true)
    })
  }

  toggle() {
    const someAreChecked = this.requiredCheckboxTargets.some(x => x.checked)

    this.requiredCheckboxTargets.forEach(c => {
      if (someAreChecked) {
        c.removeAttribute('required')
      } else {
        c.setAttribute('required', true)
      }
    })
  }
}

import { selectAll } from '../scripts/utils'

export default class Toggles {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()

    // disable all toggle__additional__item until drawer
    // is open so they won't receive focus
    const additionalItems = selectAll('.toggle__additional__item', this.el)
    additionalItems.forEach(el => {
      el.setAttribute('disabled', true)
    })
  }

  setVars() {
    this.toggles = selectAll('.toggle', this.el)
  }

  bindEvents() {
    this.toggles.forEach(el => {
      const toggle = el.querySelector('.toggle__toggle')
      const del = el.querySelector('.toggle__delete')
      const more = el.querySelector('.toggle__more')
      const additional = el.querySelector('.toggle__additional')

      if (toggle) {
        toggle.addEventListener('click', () => {
          this.handleToggle(el)
        })
      }

      if (del) {
        del.addEventListener('click', () => {
          this.handleDelete(el)
        })
      }

      if (more) {
        more.addEventListener('click', () => {
          this.toggleDrawer(el)
        })
      }

      //TODO this is not a11y as a user can not get into the info box - should refactor this
      if (additional) {
        additional.addEventListener('mouseenter', () => {
          this.openDrawer(el)
        })

        additional.addEventListener('mouseleave', () => {
          this.closeDrawer(el)
        })
      }
    })
  }

  openDrawer(el) {
    el.classList.add('active-item')
  }

  closeDrawer(el) {
    el.classList.remove('active-item')
  }

  toggleDrawer(el) {
    const additionalItems = selectAll('.toggle__additional__item', el)

    if (el.classList.contains('active-item')) {
      additionalItems.forEach(item => {
        this.closeDrawer(el)
        item.setAttribute('disabled', true)
      })
    } else {
      additionalItems.forEach(item => {
        this.openDrawer(el)
        item.removeAttribute('disabled')
      })
    }
  }

  handleDelete(el) {
    console.log(el) // eslint-disable-line
    // Presumably some code to delete stuff goes here
    // + Clean up
  }

  handleToggle(el) {
    if (el.classList.contains('active-item')) {
      el.classList.remove('active-item')
    } else {
      el.classList.add('active-item')
    }
  }
}

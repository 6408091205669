import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

export default class extends Controller {
  static values = {
    // add `data-format-currency-symbol-value="%"`
    // on the controller element to override
    symbol: { type: String, default: '$' },
    precision: { type: Number, default: 2 }
  }

  connect() {
    this.format()
  }

  format() {
    const el = this.element
    const symbol = this.symbolValue
    const precision = this.precisionValue
    const output = currency(el.value, {
      formatWithSymbol: symbol !== '%',
      symbol,
      precision
    }).format()

    el.value = symbol !== '%' ? output : `${el.value.replace('%', '')}%`
  }
}

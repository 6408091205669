import { selectAll } from '../scripts/utils'

let NUMBER_OF_STEPS = null

export default class MultiStepModal {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.nextButtons = selectAll('[data-btn="next"]', this.el)
    this.backButtons = selectAll('[data-btn="back"]', this.el)
    this.currentStep = this.el.querySelector('[data-number="step"]')
    this.stepTotal = this.el.querySelector('[data-number="total"]')
    this.activePanel = 0
    this.steps = this.el.querySelector('[data-nav="step"]')
    this.panels = this.el.querySelector('.steps-box__content')
    NUMBER_OF_STEPS = this.el.querySelectorAll('[data-nav="step"] [data-step]')
      .length
  }

  bindEvents() {
    this.nextButtons.forEach(button => {
      button.addEventListener('click', this.advance)
    })

    this.backButtons.forEach(button => {
      button.addEventListener('click', this.back)
    })
  }

  changeStep(direction) {
    if (this.activePanel < 0 || this.activePanel > NUMBER_OF_STEPS) {
      return
    }

    //update Step 1 of #
    this.currentStep.innerHTML = this.activePanel
    this.stepTotal.innerHTML = NUMBER_OF_STEPS

    if (direction === 'advance') {
      //add next active-item indicator
      this.steps
        .querySelector(`[data-step="${this.activePanel}"]`)
        .classList.add('active-item')
    } else {
      //remove previous active-item indicator
      this.steps
        .querySelector(`[data-step="${this.activePanel + 1}"]`)
        .classList.remove('active-item')
    }

    this.steps
      .querySelector(`[data-step="${this.activePanel}"]`)
      .classList.add('active-item')

    this.panels.querySelector('.active-item').classList.remove('active-item')
    this.panels
      .querySelector(`[data-step="${this.activePanel}"]`)
      .classList.add('active-item')
  }

  advance = () => {
    this.activePanel += 1
    this.changeStep('advance')
  }

  back = () => {
    this.activePanel -= 1
    this.changeStep('back')
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'officeOnlyTemplateGroup',
    'preview',
    'previewImage',
    'sender'
  ]

  connect() {
    this.toggleOfficeOnlyTemplates()
  }

  showTemplate(event) {
    if (event.params.url) {
      this.previewTarget.classList.add('-has-selection')
      this.previewImageTarget.setAttribute('src', event.params.url)
    } else {
      console.warn('missing expected parameter `url`', event.params)
    }
  }

  toggleOfficeOnlyTemplates() {
    const isAgentTeam =
      this.senderTarget.value.includes('AgentTeam') ||
      this.senderTarget.value.includes('OfficeDraft') ||
      this.senderTarget.value.includes('LeadershipSender')

    this.officeOnlyTemplateGroupTargets.forEach(el => (el.hidden = isAgentTeam))
  }
}

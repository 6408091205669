import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/dark.css'

export default class DateTimePicker {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.wrapper = this.el.parentNode
    this.label = this.wrapper.querySelector('label')
    this.calendar = this.wrapper.parentNode.querySelector('.date-field__check')
    this.fp = flatpickr(this.el, {
      enableTime: true,
      altInput: true,
      dateFormat: 'Y-m-d H:i',
      altFormat: 'h:i K o\\n m/d/Y',
      minDate: 'today',
      onReady: this.showField
    })
  }

  bindEvents() {
    this.label.addEventListener('click', this.openCalendar)
    this.calendar.addEventListener('click', this.openCalendar)
  }

  openCalendar = () => {
    this.fp.open()
  }

  showField = () => {
    this.wrapper.classList.remove('hidden-inputs')
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['drop']

  dropTargetConnected(target) {
    this._setupListeners(target, 'addEventListener')
  }

  dropTargetDisconnected(target) {
    this._setupListeners(target, 'removeEventListener')
  }

  onDropEnter = e => {
    this.dispatch('dropenter', { target: e.currentTarget })
    this.addHighlight(e)
  }

  onDropOver = e => {
    this.dispatch('dropover', { target: e.currentTarget })
    this.addHighlight(e)
  }

  onDropLeave = e => {
    this.dispatch('dropleave', { target: e.currentTarget })
    this.removeHighlight(e)
  }

  onDrop = e => {
    this.dispatch('drop', {
      target: e.currentTarget,
      detail: {
        dataTransfer: e.dataTransfer
      }
    })
    this.removeHighlight(e)
  }

  addHighlight(e) {
    e.currentTarget.dataset['highlight'] = ''
  }

  removeHighlight(e) {
    e.currentTarget.removeAttribute('data-highlight')
  }

  _setupListeners(target, method = 'addEventListener') {
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      target[method](eventName, this._preventDefault)
    })

    target[method]('dragenter', this.onDropEnter)
    target[method]('dragover', this.onDropOver)

    target[method]('dragleave', this.onDropLeave)
    target[method]('drop', this.onDrop)
  }

  _preventDefault(e) {
    e.preventDefault()
    return true
  }
}

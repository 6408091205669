import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['leadership', 'offices', 'minHandlingType', 'minCopies']

  connect() {
    this.toggleOffices()
    this.toggleMinCopies()
  }

  toggleOffices() {
    const leadership = this.leadershipTarget.checked
    this.officesTarget.style.display = leadership ? 'none' : 'grid'
    this.officesTarget
      .querySelectorAll('input')
      .forEach(i => (i.disabled = leadership))
  }

  toggleMinCopies() {
    let waiveMinimum = false

    this.minHandlingTypeTargets.forEach(o => {
      if (o.value === 'waive_minimum' && o.checked === true) {
        waiveMinimum = true
      }
    })

    this.minCopiesTarget.disabled = waiveMinimum
  }
}

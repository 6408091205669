import { selectAll } from '../scripts/utils'

export default class Selectors {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()

    if (window.location.hash) {
      this.selectTabFromHash()
    } else {
      this.selectInitialTab()
    }
  }

  setVars() {
    this.buttons = selectAll('button[data-target]', this.el)
    this.tabs = this.el.querySelector('[data-container="tabs"]')
    this.tabsControl = this.el.querySelector('.tabs')
    this.tabsItems = selectAll('.tabs li', this.el)
    this.isProgress = !!this.el.querySelector('.-progress')
  }

  bindEvents() {
    this.buttons.forEach(el => {
      el.addEventListener('click', () => {
        this.handleSelection(el)
      })
    })

    window.addEventListener('hashchange', this.selectTabFromHash.bind(this))
  }

  selectTabFromHash() {
    let target = window.location.hash.substring(1)
    let element = this.el.querySelector(`[data-target=${target}]`)

    if (element) {
      this.selectTab(target)

      if (element.dataset.autoscroll) {
        setTimeout(
          () => element.scrollIntoView({ behavior: 'smooth', block: 'start' }),
          50
        )
      }
    }
  }

  selectInitialTab() {
    let control = this.tabsControl

    if (control) {
      let target
      let initialActive = control.querySelector('.active-tab')
      if (initialActive) {
        target = control
          .querySelector('.active-tab')
          .getAttribute('data-target')
      } else {
        let lastButton = this.buttons[this.buttons.length - 1]
        target = lastButton?.getAttribute('data-target')
      }

      target && this.selectTab(target)
    }
  }

  selectTab(target, el) {
    const newActiveButton = this.el.querySelector(`[data-target=${target}]`)
    const newActiveTab = this.tabs.querySelector(`[data-tab=${target}]`)

    if (this.isProgress) {
      const backgroundColor = window
        .getComputedStyle(newActiveButton)
        .getPropertyValue('background-color')
      const index = this.tabsItems.indexOf(newActiveButton.parentNode)
      this.tabsControl.setAttribute('data-activetab', index)
      this.tabsControl.style.borderColor = backgroundColor
    }

    if (newActiveTab && newActiveButton) {
      this.el
        .querySelectorAll('.active-tab')
        .forEach(e => e.classList.remove('active-tab'))

      newActiveTab.classList.add('active-tab')
      newActiveButton.classList.add('active-tab')
    } else if (el) {
      this.el.querySelector('.active-tab').classList.remove('active-tab')
      el.classList.add('active-tab')
    }
  }

  handleSelection(el) {
    if (el.classList.contains('active-tab')) {
      return
    }

    const target = el.dataset.target

    if (target) {
      window.location.hash = target
    }
  }
}

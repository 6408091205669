import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

function formatCurrency(value) {
  return currency(value, {
    formatWithSymbol: true,
    symbol: '$'
  }).format()
}

function parseMoney(value) {
  const justNumbers = value.replace(/[^0-9.]/g, '')
  return parseFloat(justNumbers) || 0
}

export default class extends Controller {
  static values = {
    charge: Number,
    payout: Number
  }

  static targets = ['input', 'chargeTotal', 'payoutTotal']

  initialize() {
    this.charge = 0
    this.payout = 0
  }

  connect() {
    this.recalculate()
  }

  inputTargetDisconnected() {
    this.recalculate()
  }

  recalculate() {
    let newTotals = {
      charge: 0,
      payout: 0
    }

    this.inputTargets.forEach(el => {
      const config = JSON.parse(el.dataset.config)
      newTotals = this.calculateTotals(newTotals, parseMoney(el.value), config)
    })

    this.chargeValue = newTotals.charge
    this.payoutValue = newTotals.payout
  }

  /**
   * @param {Object} totals
   * @param {Number} totals.charge
   * @param {Number} totals.payout
   * @param {Number} dollarValue
   * @param {Object} inputOperations - key/value pairs for the math operations
   * example inputOperations:
   *   {charge: 'add'}
   *     or
   *   {charge: 'subtract', payout: 'subtract'}
   */
  calculateTotals(totals, dollarValue, inputOperations) {
    for (const [totalKey, operation] of Object.entries(inputOperations)) {
      if (operation === 'add') {
        totals[totalKey] += dollarValue
      } else if (operation === 'subtract') {
        totals[totalKey] -= dollarValue
      } else {
        console.warn('operation not supported:', operation)
      }
    }

    return totals
  }

  chargeValueChanged() {
    this.updateTotal(this.chargeValue, this.chargeTotalTargets)
  }

  payoutValueChanged() {
    this.updateTotal(this.payoutValue, this.payoutTotalTargets)
  }

  updateTotal(value, targets) {
    const formattedTotal = formatCurrency(value)
    targets.forEach(el => {
      if (typeof el.value === 'string') {
        el.value = formattedTotal
      } else {
        el.innerHTML = formattedTotal
      }
    })
  }
}

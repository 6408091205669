import delegate from 'delegate'

export default class Tags {
  constructor(el) {
    this.el = el
    this.handlers = []
    this.setup()
    this.bindEvents()
  }

  setup() {
    this.setVars()
  }

  setVars() {
    //TODO may add more vars here if we have other tag actions
  }

  bindEvents() {
    this.handlers.forEach(handler => handler.destroy())

    this.handlers = [
      delegate(
        this.el,
        '[data-container="close"]',
        'click',
        this.handleClick.bind(this)
      )
    ]
  }

  handleClick(e) {
    e.preventDefault()
    this.el.classList.add('hidden')
  }
}

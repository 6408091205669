import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['offices', 'agentTeams', 'markets', 'roleType', 'relatable']

  connect() {
    var relatableDiv = this.relatableTarget
    var officeSelect = this.officesTarget
    var agentTeamSelect = this.agentTeamsTarget
    var marketAdminSelect = this.marketsTarget

    officeSelect.classList.remove('hidden')
    agentTeamSelect.classList.remove('hidden')
    marketAdminSelect.classList.remove('hidden')

    this.roleTypeTarget.addEventListener('change', e => {
      if (
        e.target.value === 'Roles::OfficeManager' ||
        e.target.value === 'Roles::Accountant'
      ) {
        relatableDiv.append(officeSelect)
      } else {
        officeSelect.remove()
      }

      if (
        e.target.value === 'Roles::Agent' ||
        e.target.value === 'Roles::Assistant'
      ) {
        relatableDiv.append(agentTeamSelect)
      } else {
        agentTeamSelect.remove()
      }

      if (e.target.value === 'Roles::MarketAdmin') {
        relatableDiv.append(marketAdminSelect)
      } else {
        marketAdminSelect.remove()
      }
    })

    this.roleTypeTarget.dispatchEvent(new Event('change'))
  }
}

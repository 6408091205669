import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    queryParamName: String
  }

  change(event) {
    let selectValue = event.target.selectedOptions[0].value
    let baseUrl = `${this.urlValue}?`

    if (this.urlValue.includes('?')) {
      baseUrl = `${this.urlValue}&`
    }

    get(`${baseUrl}${this.queryParamNameValue}=${selectValue}`, {
      responseKind: 'turbo-stream'
    })
  }
}

import { safeSelect } from '../scripts/utils'

export default class Comments {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.cancel = safeSelect(this.el, '.cancel', 'button')
    this.close = safeSelect(this.el, '.close', 'button')
    this.reply = safeSelect(this.el, '.reply', 'button')
    this.commentForm = safeSelect(this.el, '.comments__form', 'form')
    this.comments = safeSelect(this.el, '.comments__body', 'div')
    this.commentsWrapper = safeSelect(this.el, '.comments', 'div')
    this.trigger = safeSelect(this.el, '[data-trigger]', 'button')
    this.hasCommentList = !!this.comments.querySelector('ul')
    this.commentsOpen = this.commentsWrapper.classList.contains('active-item')
  }

  bindEvents() {
    this.trigger.addEventListener('click', this.showAndReply)
    this.cancel.addEventListener('click', this.hideForm)
    this.close.addEventListener('click', this.toggleComments)
    this.reply.addEventListener('click', this.showForm)
  }

  showAndReply = () => {
    if (!this.hasCommentList) {
      this.commentsWrapper.removeAttribute('aria-hidden')
    }
    this.comments.removeAttribute('aria-hidden')
    this.commentsWrapper.classList.add('active-item')
    this.commentsOpen = true
    this.showForm()
  }

  toggleComments = () => {
    if (this.commentsOpen) {
      if (!this.hasCommentList) {
        this.commentsWrapper.setAttribute('aria-hidden', true)
      }

      this.comments.setAttribute('aria-hidden', true)
      this.commentsWrapper.classList.remove('active-item')
      this.commentsOpen = false
    } else {
      if (!this.hasCommentList) {
        this.commentsWrapper.removeAttribute('aria-hidden')
      }

      this.comments.removeAttribute('aria-hidden')
      this.commentsWrapper.classList.add('active-item')
      this.commentsOpen = true
    }
  }

  showForm = () => {
    this.trigger.setAttribute('disabled', true)
    this.commentForm.removeAttribute('aria-hidden')
    this.commentForm.querySelector('textarea').focus()
    this.reply.setAttribute('aria-hidden', true)
    this.showCancel()
  }

  hideForm = () => {
    this.trigger.removeAttribute('disabled')
    this.reply.removeAttribute('aria-hidden')
    this.commentForm.setAttribute('aria-hidden', true)

    if (this.hasCommentList) {
      this.showClose()
    } else {
      this.toggleComments()
    }
  }

  showCancel() {
    this.cancel.removeAttribute('aria-hidden')
    this.close.setAttribute('aria-hidden', true)
  }

  showClose() {
    this.close.removeAttribute('aria-hidden')
    this.cancel.setAttribute('aria-hidden', true)
  }
}

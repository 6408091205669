import { safeSelect } from '../scripts/utils'

export default class Accordion {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.toggle = safeSelect(this.el, '.toggle', 'button')
    this.toggleText = safeSelect(this.el, '.toggle-text', 'span')
    this.accordions = safeSelect(this.el, '.accordion__body', 'div')
    this.accordionsWrapper = safeSelect(this.el, '.accordion', 'div')
    this.trigger = safeSelect(this.el, '[data-trigger]', 'button')
    this.hasAccordionList = !!this.accordions.querySelector('ul')
    this.accordionsOpen = this.accordionsWrapper.classList.contains(
      'active-item'
    )
  }

  bindEvents() {
    this.toggle.addEventListener('click', this.toggleAccordions)
  }

  toggleAccordions = () => {
    if (this.accordionsOpen) {
      if (!this.hasAccordionList) {
        this.accordionsWrapper.setAttribute('aria-hidden', true)
      }

      this.toggleText.innerHTML = 'Show'
      this.toggle.setAttribute('aria-expanded', false)
      this.accordions.setAttribute('aria-hidden', true)
      this.accordionsWrapper.classList.remove('active-item')
      this.accordionsOpen = false
    } else {
      if (!this.hasAccordionList) {
        this.accordionsWrapper.removeAttribute('aria-hidden')
      }
      this.toggleText.innerHTML = 'Hide'
      this.toggle.setAttribute('aria-expanded', true)
      this.accordions.removeAttribute('aria-hidden')
      this.accordionsWrapper.classList.add('active-item')
      this.accordionsOpen = true
    }
  }
}

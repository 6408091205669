import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slotTypeSelect', 'nameInput']

  connect() {
    this.slotTypeSelectTargets.forEach(select => {
      this.setSlotType(select.value)
    })
  }

  slotTypeChange(event) {
    this.setSlotType(event.target.value)
  }

  setSlotType(slotType) {
    if (slotType === 'other') {
      this.slotTypeSelectTarget.classList.add('hidden')
      this.nameInputTarget.classList.remove('hidden')
      this.nameInputTarget.focus()
    }
  }
}

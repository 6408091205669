export default class Dropdown {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.button = this.el.querySelector('.trigger')
  }

  bindEvents() {
    this.button.addEventListener('click', this.toggle)
    document.body.addEventListener('click', this.close)
  }

  toggle = e => {
    const { currentTarget } = e
    e.stopPropagation()
    e.preventDefault()

    if (currentTarget.classList.contains('active-item')) {
      this.button.setAttribute('aria-expanded', false)
    } else {
      this.button.setAttribute('aria-expanded', true)
    }

    currentTarget.classList.toggle('active-item')
  }

  close = () => {
    if (this.button.classList.contains('disable_trigger') === false) {
      this.button.classList.remove('active-item')
      this.button.setAttribute('aria-expanded', false)
    }
  }
}

import * as Sentry from '@sentry/browser'

// Sentry setup for various environments
/* eslint-disable indent */
/* eslint-disable no-undef */
let railsEnv = process.env.RAILS_ENV
let sentryRelease = process.env.SENTRY_RELEASE
/* eslint-enable no-undef */

if (railsEnv) {
  let dsn
  switch (railsEnv) {
    case 'production':
      dsn = 'https://f0f8dc6ea5ba494c937554b07aad488f@sentry.io/1248540'
      break
    case 'integration':
      dsn = 'https://ca8284cbc7304e8491c6b7f722b5273d@sentry.io/1248536'
      break
    case 'staging':
      dsn = 'https://d4bc0741a51549a4b59897d086c6953c@sentry.io/1248541'
      break
    default:
      console.error('[sentry] no match for rails env', railsEnv)
      break
  }

  if (dsn) {
    Sentry.init({
      dsn: dsn,
      release: sentryRelease
    })
  }
}

Sentry.configureScope(scope => {
  scope.setUser(getUserData())
})

/* eslint-enable indent */

function getUserData() {
  const data = document.getElementById('UserData')

  if (data) {
    try {
      return JSON.parse(data.textContent)
    } catch (e) {
      console.error(`failed to parse json with error: ${e}`) // eslint-disable-line
      return {}
    }
  } else {
    console.warn('No UserData given.') // eslint-disable-line
    return {}
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'itemTemplate', 'itemDestroyInput', 'itemFieldset']

  static values = {
    index: Number
  }

  addItem() {
    const newItem = this.itemTemplateTarget.content.cloneNode(true)
    this.indexValue += 1
    this.setIndexes(newItem, this.indexValue)
    this.element.insertBefore(newItem, this.itemTemplateTarget)
  }

  deleteItem(event) {
    const itemEl = event.currentTarget.closest('[data-has-many-target="item"]')
    const itemFieldsetEl = itemEl.querySelector(
      '[data-has-many-target="itemFieldset"]'
    )

    if (itemFieldsetEl) {
      itemFieldsetEl.parentNode.removeChild(itemFieldsetEl)
    }

    const destroyInputEl = itemEl.querySelector(
      '[data-has-many-target="itemDestroyInput"]'
    )

    if (destroyInputEl) {
      destroyInputEl.checked = true
    }
  }

  setIndexes(node, index) {
    node.querySelectorAll('input, select').forEach(function(el) {
      el.name = el.name.replace(/\d+/, index)
      el.id = el.id.replace(/\d+/, index)
    })
  }
}

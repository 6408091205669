import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import * as Sentry from '@sentry/browser'

// start stimulus and load controllers
const application = Application.start()
const controllers = import.meta.globEager(
  '../../controllers/**/*_controller.js'
)
registerControllers(application, controllers)

const stimulusHandleError = application.handleError.bind(application)

// capture stimulus errors with Sentry
const sentryErrorHandler = (error, message, detail = {}) => {
  stimulusHandleError(error, message, detail)
  Sentry.captureException(error, { message, ...detail })
}

application.handleError = sentryErrorHandler

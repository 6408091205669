import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'item']

  toggleItemHidden(event) {
    if (event.target.checked) {
      this.item.setAttribute('hidden', '')
    } else {
      this.item.removeAttribute('hidden')
    }
  }
}

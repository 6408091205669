import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'descendantCheckbox']

  toggleAll(event) {
    const checked = event.target.checked
    this.descendantCheckboxTargets.forEach(c => {
      c.checked = checked
      // manually dispatch the change event for other
      // possible use, like with `checkbox_required_controller`
      c.dispatchEvent(new Event('change'))
    })
  }
}

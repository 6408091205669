import 'date-input-polyfill'
import axios from 'axios'
import debounce from 'lodash.debounce'
import currency from 'currency.js'
import delegate from 'delegate'
import { selectAll } from '../scripts/utils'
import { FOCUSED_CLASS } from '../scripts/constants'

export default class Forms {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()

    const autofocusEl = this.el.querySelector('[autofocus=autofocus]')

    if (autofocusEl === document.activeElement) {
      autofocusEl.parentNode.classList.add(FOCUSED_CLASS)
    }
  }

  setVars() {
    this.Inputs = selectAll(
      'input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="tel"], input[type="number"], textarea, select',
      this.el
    )
    this.BackgroundSubmit = selectAll('[data-submit="background"]', this.el)
    this.AutoSubmit = selectAll('[data-submit="auto"]', this.el)
  }

  bindEvents() {
    if (
      this.Inputs.length === 0 &&
      this.BackgroundSubmit.length === 0 &&
      this.AutoSubmit.length === 0
    ) {
      return
    }

    this.AutoSubmit.forEach(el => {
      const field = el.querySelector('select')

      field.addEventListener('change', this.autoSubmitForm)
    })

    this.BackgroundSubmit.forEach(el => {
      // Date autosubmit
      const dateField = el.querySelector('.type-date-input')
      if (dateField) {
        // prevent enter key from submitting form
        dateField.closest('form').onsubmit = function(e) {
          e.preventDefault()
        }

        dateField.addEventListener('change', this.postDatefieldForm)
        dateField.addEventListener('focus', this.focusDateField)
        dateField.addEventListener('blur', this.blurDateField)
      }

      // Text/Select autosubmit
      const field =
        el.querySelector('input[type="text"]') || el.querySelector('select')

      if (field) {
        // prevent enter key from submitting form
        field.closest('form').onsubmit = function(e) {
          e.preventDefault()
        }

        if (field.tagName === 'SELECT') {
          field.addEventListener('change', this.postFieldForm)
        } else {
          field.addEventListener('keyup', debounce(this.postFieldForm, 1000))
        }

        field.addEventListener('focus', this.focusField)
        field.addEventListener('blur', this.blurField)
      }
    })

    this.Inputs.forEach(el => {
      el.addEventListener('focus', this.addFocusedClass)
    })

    this.Inputs.forEach(el => {
      el.addEventListener('blur', this.removeFocusedClass)
    })

    delegate('[data-format]', 'change', this.formatNumber)
  }

  focusField = e => {
    const form = e.target.parentNode.parentNode
    form.classList.remove('-filled')
  }

  blurField = e => {
    const form = e.target.parentNode.parentNode
    if (e.target.value) {
      form.classList.add('-filled')
    }
  }

  autoSubmitForm = e => {
    const wrapper = e.target.parentNode
    const form = wrapper.closest('form')

    form.submit()
  }

  postFieldForm = e => {
    const wrapper = e.target.parentNode
    const form = wrapper.parentNode
    const formData = new FormData()
    let inputs = selectAll('input', form)
    const selects = selectAll('select', form)

    if (selects) {
      inputs = inputs.concat(selects)
    }

    if (!e.target.value) {
      return
    }

    inputs.forEach(el => {
      const name = el.getAttribute('name')
      if (name === 'financial[input_value]') {
        formData.append(name, e.target.value)
      } else {
        formData.append(name, el.getAttribute('value'))
      }
    })

    axios({
      method: 'post',
      url: form.getAttribute('action'),
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(data => {
        if (data.status === 200 && !!e.target.value) {
          form.classList.add('-filled')
        } else {
          form.classList.remove('-filled')
        }
      })
      .catch(data => {
        console.error(data) // eslint-disable-line
      })
  }

  focusDateField = e => {
    const label = e.target.parentNode
    label.classList.remove('-filled')
  }

  blurDateField = e => {
    const label = e.target.parentNode
    if (e.target.value) {
      label.classList.add('-filled')
    }
  }

  postDatefieldForm = e => {
    const label = e.target.parentNode
    const form = label.parentNode
    const formData = new FormData()
    const inputs = selectAll('input', form)
    const year = parseInt(e.target.value.split('-')[0])

    if (e.target.value && year < 999) {
      return
    }

    if (year > 9999) {
      let parts = e.target.value.split('-')
      parts[0] = parts[0].substring(0, 4)
      e.target.value = parts.join('-')
    }

    inputs.forEach(el => {
      const name = el.getAttribute('name')
      if (name === 'key_date[value]') {
        formData.append(name, e.target.value)
      } else {
        formData.append(name, el.getAttribute('value'))
      }
    })

    axios({
      method: 'post',
      url: form.getAttribute('action'),
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(data => {
        if (data.status === 200 && !!e.target.value) {
          label.classList.add('-filled')
        } else {
          label.classList.remove('-filled')
        }
      })
      .catch(data => {
        console.error(data) // eslint-disable-line
      })
  }

  addFocusedClass = e => {
    e.target.parentNode.classList.add(FOCUSED_CLASS)
  }

  removeFocusedClass = e => {
    e.target.parentNode.classList.remove(FOCUSED_CLASS)
  }

  // method deprecated. use format_currency_controller.js going forward
  formatNumber = e => {
    console.warn(
      '`formatNumber` is deprecated. migrate usage to format_currency_controller.js.'
    )
    const symbol = e.target.dataset.format
    const output = currency(e.target.value, {
      formatWithSymbol: symbol !== '%',
      symbol: symbol || '$',
      precision: e.target.dataset.noDecimal ? 0 : 2
    }).format()

    e.target.value =
      symbol !== '%' ? output : `${e.target.value.replace('%', '')}%`
  }
}

import delegate from 'delegate'

export default class InlineEdit {
  constructor(el) {
    this.el = el
    this.handlers = []
    this.setup()
  }

  setup() {
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.contentContainer = this.el.querySelector('[data-container="content"]')
    this.formContainer = this.el.querySelector('[data-container="form"]')
    this.usesExternalLink = !!this.el.getAttribute('data-external')
    this.actualForm =
      this.formContainer && this.formContainer.querySelector('form')

    if (this.usesExternalLink) {
      this.replaceable = this.el.querySelector('[data-container="replaceable"]')
    }

    this.checkboxForm = this.el.querySelector(
      '[data-container="checkbox-form"]'
    )
  }

  bindEvents() {
    if (!this.formContainer) return

    this.handlers.forEach(handler => handler.destroy())

    this.handlers = [
      delegate(
        this.el,
        '[data-link="edit"]',
        'click',
        this.handleEdit.bind(this)
      ),
      delegate(
        this.el,
        '[data-link="cancel"]',
        'click',
        this.handleCancel.bind(this)
      ),
      delegate(
        this.el,
        '[data-link="submit"]',
        'click',
        this.handleSubmit.bind(this)
      )
    ]

    this.actualForm.addEventListener('ajax:complete', e => {
      this.handleResponse(e)
    })

    if (this.checkboxForm) {
      this.checkboxForm.addEventListener('ajax:complete', e => {
        this.handleResponse(e)
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    if (this.actualForm.dataset.remote) {
      // submits the form using rails-ujs so the `ajax:complete`
      window.Rails.fire(this.actualForm, 'submit')
    } else {
      window.form.submit()
    }
  }

  handleEdit(e) {
    e.preventDefault()

    this.contentContainer.classList.add('hidden')
    this.formContainer.classList.remove('hidden')
  }

  handleCancel(e) {
    e.preventDefault()

    this.formContainer.classList.add('hidden')
    this.contentContainer.classList.remove('hidden')
  }

  handleResponse(e) {
    let data = e.detail[0]

    if (data.status === 200) {
      if (this.usesExternalLink) {
        this.replaceable.innerHTML = data.response
      } else {
        this.el.innerHTML = data.response
      }

      this.setup()

      if (this.el.querySelector('.hide__link')) {
        window.setupHides()
      }

      if (this.formContainer && this.formContainer.dataset.module) {
        const moduleName = this.formContainer.dataset.module

        import(`../modules/${moduleName}.js`).then(
          Module => new Module.default(this.formContainer)
        )
      }
    }
  }
}
